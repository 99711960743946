
.desktopview{
    display: block;
}

.buttons{
    background:rgb(235,86,57) ;
    color:#fff;
    outline:none;
    border:none;
    cursor: pointer;
    font-weight: 500;
    justify-content: center;
}
.correctbuttons{
    background: green ;
    color:#fff;
    outline:none;
    border:none;
    cursor: not-allowed;
    font-weight: 500;
    justify-content: center;
}
.incorrectbuttons{
    background: red;
    color:#fff;
    outline:none;
    border:none;
    cursor: not-allowed;
    font-weight: 500;
    justify-content: center;
}
.incorrectbuttons:hover{
    background: red !important;
    color:#fff;
    outline:none;
    border:none;
    cursor: not-allowed;
    font-weight: 500;
    justify-content: center;
}
.correctbuttons:hover{
    background: green !important;
    color:#fff;
    outline:none;
    border:none;
    cursor: not-allowed;
    font-weight: 500;
    justify-content: center;
}
.buttons:disabled{
    background: grey !important;
    cursor: not-allowed;
}
.mobileview{
    display: none;
}
@media (max-width: 768px){
    .mobileview{
        display: block;
    }

    .desktopview{
        display: none;
    }
}
