.modalBackground {
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 0, 0, 0.2);;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    z-index: 999;
  }
 
  
  .modalContainer {
    width: auto;
    height: auto;
    min-width: 40vw;
    min-height: 40vh;
    overflow-y: scroll;
    overflow-x: scroll;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    /* display: flex; */
    /* flex-direction: column; */
    padding: 25px;
    z-index: 99999;
  /* background-repeat: no-repeat;
  background-size: cover;
   background-image: url("https://firebasestorage.googleapis.com/v0/b/bharatkojanofinale.appspot.com/o/popup_background.png?alt=media&token=7278c2ea-3749-446a-a10d-0aae9733a9cc"); */

  }
  
  .modalContainer .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
  
  }
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
    position: fixed;
    padding: 5px;
    border: 1px solid black;
  }
  
  .modalContainer .body {
    /* flex: 50%; */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    font-size: 1rem;
    line-height: 1.9rem;
    /* text-align: center; */
  }
  
  .modalContainer .footer {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContainer .footer button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    background-color: cornflowerblue;
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
  }
  
  #cancelBtn {
    background-color: crimson;
  }

  .modalContainer::-webkit-scrollbar {
    display: none;
}

.modal-content{
  width: 200% !important;
}